<template>
  <validation-observer ref="addWeekForm">
    <b-form>
      <b-modal
        id="bv-modal-AddWeek"
        ref="bv-modal-AddWeek"
        centered
        hide-footer
        :title="
          isEdit
            ? $t('g.add_week_overlay/title_edit')
            : $t('g.add_week_overlay/title')
        "
      >
        <span class="sub-title position-relative m-2">
          {{ $t("g.add_week_overlay/form/header") }}
        </span>
        <div class="add-week-overlay">
          <b-form-group label-for="name" class="m-2">
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                type="text"
                v-model="form_data.name"
                :placeholder="$t('g.name')"
                name="name"
                class="input"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="description" class="mx-2">
            <validation-provider
              #default="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-textarea
                rows="5"
                max-rows="10"
                v-model="form_data.description"
                no-resize
                :placeholder="$t('g.description')"
                name="description"
                class="input text-area"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>
          <b-row class="px-2">
            <b-col cols="12" sm="6">
              <b-form-group label-for="start date">
                <validation-provider
                  #default="{ errors }"
                  name="start date"
                  rules="required"
                >
                  <b-form-datepicker
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    :placeholder="$t('g.start_date')"
                    v-model="form_data.start_date"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group label-for="end_date">
                <validation-provider
                  #default="{ errors }"
                  name="end_date"
                  rules="required"
                >
                  <b-form-datepicker
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    :placeholder="$t('g.end_date')"
                    v-model="form_data.end_date"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-file
            ref="fileInput"
            id="fileInput"
            :value="null"
            :hidden="true"
            v-model="form_data.path"
            accept=".jpg, .png, .gif"
            plain
          />
          <b-row class="mx-2" v-if="form_data.path == null">
            <div
              class="import-continier w-100"
              @click="$refs.fileInput.$el.click()"
            >
              <span class="box">
                <img :src="importImge" alt="image-import" />
                <img class="mt-3 ml-n1" :src="importIco" alt="image-import" />
                <span>{{
                  $t("g.add_week_overlay/form/import_file_text")
                }}</span>
              </span>
              <br />
            </div>
          </b-row>
          <b-row v-else class="mx-1">
            <span>{{ $t("g.add_week_overlay/form/media_section") }}</span>
            <div class="path-preview w-100">
              <img
                class="sm-img-preview"
                :src="pdfIco"
                alt="Instructions-Type"
              />
              <span class="name"> {{ form_data.path.name }}</span>
              <label for="fileInput" class="float-right" @click="deleteItem">
                <span><img :src="PenIco" alt="pen-tool" /></span>
              </label>
            </div>
          </b-row>
        </div>
        <b-row>
          <b-col class="d-flex justify-content-end mt-2">
            <b-button
              variant="outline-primary"
              class="mx-1"
              @click="$bvModal.hide('bv-modal-AddWeek')"
              >{{ $t("g.cancel") }}</b-button
            >
            <b-button
              variant="primary"
              @click="validateForm()"
              ref="formButton"
              >{{ isEdit ? $t("g.edit") : $t("g.save") }}</b-button
            >
          </b-col>
        </b-row>
        <overlay-component :isLoading="isloading" />
      </b-modal>
    </b-form>
  </validation-observer>
</template>
<script>
import {
  BRow,
  BCol,
  BModal,
  BButton,
  BForm,
  BFormInput,
  BFormFile,
  BFormTextarea,
  BFormDatepicker,
  BFormGroup,
} from "bootstrap-vue";

import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import OverlayComponent from "@/components/shared/OverlayComponent/index.vue";
import loading from "@/components/loading/loading.vue";

export default {
  name: "addWeekOverlay",
  props: ["course_id", "id_to_edit"],
  data() {
    return {
      dateOption: {
        month: "short",
        weekday: "short",
      },
      form_data: {
        name: null,
        description: null,
        start_date: null,
        end_date: null,
        path: null,
        is_completion_required: 1,
        is_visible: 1,
        is_locked: 0,
        color: 1,
      },
      isloading: false,
      localCourseId: null,
      isEdit: false,
      editingId: null,
      importImge: require("@/assets/images/icons/importPhoto.png"),
      importIco: require("@/assets/images/icons/plusblue.svg"),
      pdfIco: require("@/assets/images/icons/pdf.png"),
      fileIco: require("@/assets/images/icons/doc.png"),
      PenIco: require("@/assets/images/icons/pencil.svg"),
    };
  },
  mounted() {
    this.clearFormData();
    this.localCourseId = this.course_id;
  },
  components: {
    BCol,
    BRow,
    BModal,
    BButton,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BFormDatepicker,
    ValidationObserver,
    ValidationProvider,
    loading,
    OverlayComponent,
  },
  watch: {
    id_to_edit(newVal, oldVal) {
      if (newVal != null) {
        this.isEdit = true;
        this.editingId = newVal;
        this.getCurrentWeekData();
        this.clearFormData();
      } else {
        this.isEdit = false;
        this.editingId = null;
        this.clearFormData();
      }
    },
  },
  methods: {
    clearFormData() {
      for (let item in this.form_data) {
        let notAllowedItems = [
          "resources",
          "is_completion_required",
          "color",
          "is_visible",
          "is_locked",
          "is_required",
          "is_assignment",
        ];
        if (!notAllowedItems.includes(item)) {
          this.form_data[item] = null;
        }
        if (item == "resources") {
          this.form_data.resources.length = 0;
        }
      }
    },
    validateForm() {
      this.$refs.formButton.disabled = true;

      this.$refs.addWeekForm.validate().then((sucess) => {
        if (sucess) {
          if (this.isEdit) {
            this.submitEditform();
          } else {
            this.submitform();
          }
        } else {
          this.$refs.formButton.disabled = false;
          console.log("error");
        }
      });
    },
    submitform() {
      const formData = new FormData();
      for (let item in this.form_data) {
        formData.append(item, this.form_data[item]);
      }
      this.$http
        .post(`admin/courses/${this.localCourseId}/lessons`, formData)
        .then((response) => {
          this.makeToast(
            "success",
            response.data.message,
            this.$t("g.toast/header_successfully")
          );
          this.$bvModal.hide("bv-modal-AddWeek");
          this.$refs.formButton.disabled = false;
          this.$emit("resetData");
        })
        .catch((error) => {
          for (const key in error.response?.data?.errors) {
            this.makeToast(
              "danger",
              error.response.data.errors[key][0],
              this.$t("g.send.errorTitle")
            );
          }
          this.$refs.formButton.disabled = false;
        });
    },
    submitEditform() {
      const formData = new FormData();
      for (let item in this.form_data) {
        formData.append(item, this.form_data[item]);
      }
      this.$http
        .post(
          `admin/courses/${this.localCourseId}/lessons/${this.editingId}?_method=put`,
          formData
        )
        .then((response) => {
          this.makeToast(
            "success",
            response.data.message,
            this.$t("g.toast/header_successfully")
          );
          this.$bvModal.hide("bv-modal-AddWeek");
          this.$refs.formButton.disabled = false;
          this.$emit("resetData");
        })
        .catch((error) => {
          for (const key in error.response?.data?.errors) {
            this.makeToast(
              "danger",
              error.response.data.errors[key][0],
              this.$t("g.send.errorTitle")
            );
          }
          this.$refs.formButton.disabled = false;
        });
    },
    deleteItem() {
      this.$refs.fileInput.$el.click();
      this.form_data.path = null;
    },
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    getCurrentWeekData() {
      this.isloading = true;
      const id = this.editingId;
      this.$http
        .get(`admin/courses/${this.localCourseId}/lessons/${id}`)
        .then((response) => {
          this.isloading = false;
          for (let item in this.form_data) {
            this.form_data[item] = response.data.data[item];
            if (item == "path") {
              this.form_data["path"] =
                response.data.data.course?.image &&
                response.data.data.course.image[0];
            }
            if (
              item == "is_completion_required" ||
              item == "is_visible" ||
              item == "is_locked"
            ) {
              this.form_data[item] = response.data.data[item] ? 1 : 0;
            }
          }
        })
        .catch((err) => {
          this.makeToast("danger", err.message, this.$t("g.send.errorTitle"));
        });
    },
  },
};
</script>
<style lang="scss" src="./_addWeekOverlay.scss"></style>
