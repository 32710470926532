<template>
  <div class="sectionNavbar" v-if="page_data">
    <div class="custom-breadcrumb d-flex justify-content-between pl-1">
      <bread-crumb />
      <div class="arrows">
        <div
          class="arrow mr-2 cursor-pointer"
          @click="prev"
          v-if="currentIndex != 0"
        >
          <img :src="require('@/assets/images/icons/arrow-p.png')" alt="" />
          {{ $t("g.previous") }}
        </div>
        <div
          class="arrow cursor-pointer"
          @click="next"
          v-if="
            listSections &&
            listSections.length > 0 &&
            currentIndex < listSections.length - 1
          "
        >
          {{ $t("g.next") }}
          <img :src="require('@/assets/images/icons/arrow-n.png')" alt="" />
        </div>
      </div>
    </div>
    <div class="ml-1">
      <div class="data">
        <img :src="Calenderico" alt="" />
        <span v-if="page_data.is_assignment === true">
          <span class="variant-primary"
            >{{ $t("g.from") }}
            <span class="ml-0">{{
              dateHandeling(page_data.assignment.start_date, true)
            }}</span></span
          >
          <span class="variant-primary"
            >{{ $t("g.to") }}
            <span class="ml-0">{{
              dateHandeling(page_data.assignment.due_date, true)
            }}</span></span
          >
        </span>
        <span v-else>
          <span class="variant-primary"
            >{{ $t("g.from") }}
            <span class="ml-0">{{
              dateHandeling(page_data.created_at, false)
            }}</span></span
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import breadCrumb from "@/@core/layouts/components/AppBreadcrumb.vue";
export default {
  name: "SectionNavbar",

  props: ["page_data", "course_id", "lesson_id", "section_id"],
  data() {
    return {
      currentIndex: null,
      listSections: null,
      currentSectionId: this.section_id,
      Calenderico: require("@/assets/images/icons/calendar.png"),
      imgTime: require("@/assets/images/icons/time.png"),
    };
  },
  components: {
    breadCrumb,
  },
  watch: {
    lesson_id(newVal, oldVal) {
      this.getlistdata();
    },
    section_id(is, was) {
      this.currentSectionId = is;
      this.getCurrentSectionIndex();
    },
    currentSectionId(newval, oldVal) {
      this.$emit("openSection", this.lesson_id, newval);
    },
    currentIndex(newVal, oldVal) {
      this.currentSectionId = this.listSections[newVal]["id"];
    },
  },
  beforeMount() {
    this.data = this.page_data;
    this.getlistdata();
  },
  methods: {
    next() {
      const maxIndex = this.listSections?.length;
      if (this.currentIndex < maxIndex) {
        this.currentIndex = this.currentIndex + 1;
      }
    },
    prev() {
      if (this.currentIndex != 0) {
        this.currentIndex = this.currentIndex - 1;
      }
    },
    getCurrentSectionIndex() {
      const currentSectionId = this.section_id;
      let list = this.listSections;
      for (let i = 0; i < list?.length; i++) {
        if (list[i].id == currentSectionId) {
          this.currentIndex = i;
        }
      }
    },
    getlistdata() {
      this.$http
        .get(
          `admin/courses/${this.course_id}/lessons/${this.lesson_id}/sections`
        )
        .then((response) => {
          this.listSections = response.data.data;
          this.getCurrentSectionIndex();
        })
        .catch((error) => console.log(error));
    },
    getAllData() {
      this.$http
        .get(
          `admin/courses/${this.course_id}/lessons/${this.lesson_id}/sections/${this.currentSectionId}`
        )
        .then((response) => {
          this.data = response?.data.data;
        });
    },
    dateHandeling(dateToconvert, time) {
      const dateObj = new Date(dateToconvert);

      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      let date = dateObj.getDate();
      if (date < 10) {
        date = "0" + date;
      }

      const month = months[dateObj.getMonth()];
      const year = dateObj.getFullYear();

      let hours = dateObj.getHours();
      if (hours < 10) {
        hours = "0" + hours;
      }

      let minutes = dateObj.getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      }

      if (time) {
        return date + " " + month + " " + year + "-" + hours + " " + minutes;
      } else {
        return date + " " + month + " " + year;
      }
    },
  },
};
</script>

<style src="./_sectionNavbar.scss" lang="scss" scoped></style>
