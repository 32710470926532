import { render, staticRenderFns } from "./SectionResources.vue?vue&type=template&id=08b38aa5&scoped=true&"
import script from "./SectionResources.vue?vue&type=script&lang=js&"
export * from "./SectionResources.vue?vue&type=script&lang=js&"
import style0 from "./_sectionResources.scss?vue&type=style&index=0&id=08b38aa5&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b38aa5",
  null
  
)

export default component.exports