var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.page_data)?_c('div',{staticClass:"weeks-sidebar"},[_c('div',{staticClass:"week-header"},[_c('h3',{staticClass:"course-title"},[_vm._v(_vm._s(_vm.page_data ? _vm.page_data.name : null))])]),_c('div',{staticClass:"week-content"},_vm._l((_vm.page_data.lessons),function(lesson){return _c('b-card',{key:lesson.id,class:_vm.activeCollapse === lesson.id
          ? 'week-box-component mb-1 active'
          : 'week-box-component mb-1'},[_c('b-card-header',{class:_vm.activeCollapse === lesson.id
            ? 'active'
            : lesson.is_locked
            ? 'visiable'
            : null,on:{"click":function($event){return _vm.toggleCollapse(lesson.id, lesson.is_locked)}}},[_c('div',{class:_vm.activeCollapse === lesson.id
              ? 'card-header-text active'
              : 'card-header-text'},[(lesson.name.split('').length < 15)?_c('span',[_vm._v(" "+_vm._s(lesson.name)+" ")]):_c('span',[_c('span',{attrs:{"id":'lesson-name-' + "" + (lesson.id)}},[_vm._v(" "+_vm._s(lesson.name.substring(0, 15))+" .. ")]),_c('b-tooltip',{attrs:{"target":'lesson-name-' + "" + (lesson.id),"variant":"light","triggers":"hover"}},[_c('b',[_vm._v(" "+_vm._s(lesson.name)+" ")])])],1)]),_c('div',[(
              _vm.permissions.lessons &&
              (_vm.permissions.lessons.update || _vm.permissions.lessons.destroy)
            )?_c('b-dropdown',{attrs:{"dropleft":_vm.lang === 'ar',"dropright":_vm.lang === 'en',"no-caret":"","variant":"transparent"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('img',{attrs:{"src":_vm.activeCollapse === lesson.id ? _vm.white_dots : _vm.dots,"alt":"three-dots"}})]},proxy:true}],null,true)},[(_vm.permissions.lessons && _vm.permissions.lessons.update)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.editLesson(lesson.id)}}},[_vm._v(" "+_vm._s(_vm.$t("g.edit")))]):_vm._e(),(_vm.permissions.lessons && _vm.permissions.lessons.destroy)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteLesson(lesson.id)}}},[_vm._v(" "+_vm._s(_vm.$t("g.delete")))]):_vm._e(),(_vm.permissions.lessons && _vm.permissions.lessons.update)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.toggleVisulabiltyofLesson(lesson.id, lesson.is_locked)}}},[_vm._v(" "+_vm._s(!lesson.is_locked ? _vm.$t("g.lock") : _vm.$t("g.open"))+" ")]):_vm._e()],1):_vm._e(),_c('b-img',{attrs:{"src":_vm.activeCollapse === lesson.id ? _vm.arrowImg_w : _vm.arrowImg,"alt":"arrow icon"}})],1)]),_c('b-collapse',{attrs:{"visible":_vm.activeCollapse === lesson.id}},[_c('b-card-body',[_vm._l((lesson.sections),function(section){return _c('b-card-text',{key:section.id},[_c('div',{staticClass:"custom-div",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openSection(lesson.id, section.id)}}},[_c('div',[_c('img',{attrs:{"src":_vm.getSectionIcon(section),"alt":"arrow icon"}}),_c('span',{staticStyle:{"width":"100% !important"}},[_vm._v(" "+_vm._s(section.name)+" ")])]),_c('span',{staticClass:"actions-menu",staticStyle:{"align-self":"flex-end !important"}},[(
                    section.is_assignment
                      ? _vm.permissions.assignments.destroy
                      : _vm.permissions.sections.destroy
                  )?_c('b-dropdown',{attrs:{"dropleft":_vm.lang === 'ar',"dropright":_vm.lang === 'en',"no-caret":"","variant":"transparent"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('img',{staticStyle:{"align-self":"end"},attrs:{"src":_vm.dots,"alt":"three-dots"}})]},proxy:true}],null,true)},[(
                      section.is_assignment
                        ? _vm.permissions.assignments.update
                        : _vm.permissions.sections.update
                    )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.editSection(lesson.id, section)}}},[_vm._v(" "+_vm._s(_vm.$t("g.edit")))]):_vm._e(),(_vm.permissions.sections.destroy)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteSection(
                        lesson.id,
                        section.id,
                        section.is_assignment
                      )}}},[_vm._v(" "+_vm._s(_vm.$t("g.delete")))]):_vm._e()],1):_vm._e()],1)])])}),(
              _vm.permissions.sections &&
              (_vm.permissions.sections.store || _vm.permissions.assignments.store)
            )?_c('div',{staticClass:"add-section"},[_c('b-dropdown',{attrs:{"dropright":_vm.lang === 'en',"dropleft":_vm.lang === 'ar',"variant":"tarnsparent ","id":"dropdown-dropright"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-inline ml-n1 p-0 w-100"},[_c('img',{attrs:{"src":_vm.plus,"alt":"plus-icon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.add")))])])]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.AddNewLesson('lesson')}}},[_vm._v(" "+_vm._s(_vm.$t("g.lesson")))]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.AddNewLesson('liveLesson')}}},[_vm._v(" "+_vm._s(_vm.$t("g.live_lesson"))+" ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.AddNewLesson('quiz')}}},[_vm._v(" "+_vm._s(_vm.$t("g.asseigment"))+" ")])],1)],1):_vm._e()],2)],1)],1)}),1),_c('AddWeekOverlay',{attrs:{"id_to_edit":_vm.weekIdSentToOverlay,"course_id":_vm.page_data.id},on:{"resetData":function($event){return _vm.$emit('resetData')}}}),_c('AddLessonOverlay',{attrs:{"id_to_edit":_vm.sectionIdToSentNewLesson,"course_id":_vm.page_data.id,"section_id":_vm.activeCollapse},on:{"getSetcionId":_vm.updateLoaclToSection,"resetData":function($event){return _vm.$emit('resetData')}}}),_c('AddLiveLessonOverlay',{attrs:{"id_to_edit":_vm.sectionIdToSentNewLiveLesson,"course_id":_vm.page_data.id,"section_id":_vm.activeCollapse},on:{"getSectionId":_vm.updateLoaclToSection,"resetData":function($event){return _vm.$emit('resetData')}}}),_c('AddQuizOverlay',{attrs:{"id_to_edit":_vm.sectionIdToSentNewQuiz,"course_id":_vm.page_data.id,"section_id":_vm.activeCollapse},on:{"getSetcionId":_vm.updateLoaclToSection,"resetData":function($event){return _vm.$emit('resetData')}}}),_c('OverlayComponent',{attrs:{"isLoading":_vm.loading}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }