<template>
  <content-not-view
    v-if="
      subPermissions && subPermissions.courses && !subPermissions.courses.show
    "
  />
  <div class="course-details" v-else>
    <div
      class="custom-sidebar"
      :style="{ left: isCollapsed ? '90px' : '260px' }"
    >
      <WeeksSidebar
        :page_data="courseDetails"
        @openSection="handleOpneSection"
        @resetData="getCourseDetails"
        :w_loading="WeekSideBarLoading"
        :permissions="subPermissions"
      ></WeeksSidebar>
    </div>
    <div class="course-content">
      <ShowSection
        :page_data="courseDetails"
        :lesson_id="lesson_id"
        :section_id="section_id"
      ></ShowSection>
    </div>
  </div>
</template>

<script>
// import store from "@/store";
import WeeksSidebar from "@/components/classrooms/weeks/weekSidebar/WeeksSidebar.vue";
import ShowSection from "@/components/classrooms/weeks/sections/showSection/ShowSection.vue";
import { BRow, BCol } from "bootstrap-vue";
import contentNotView from "@/components/contentNotView/contentNotView.vue";

export default {
  name: "CourseDetails",
  components: {
    WeeksSidebar,
    ShowSection,
    BRow,
    BCol,
    contentNotView,
  },
  data() {
    return {
      course_id: null,
      lesson_id: null,
      section_id: null,
      courseDetails: null,
      WeekSideBarLoading: false,
    };
  },

  mounted() {
    if (this.$route.params.id) {
      this.course_id = +this.$route.params.id;
      this.getCourseDetails();
    }
  },
  computed: {
    isCollapsed() {
      return this.$store.state.verticalMenu.isVerticalMenuCollapsed
        ? true
        : false;
    },
    subPermissions() {
      this.$store.dispatch("GET_PERMISSIONS", [
        "lessons",
        "sections",
        "assignments",
        "courses",
      ]);
      return this.$store.state.userData.sub_Permissions;
    },
  },
  methods: {
    getCourseDetails() {
      this.WeekSideBarLoading = true;
      this.$http
            .get(`admin/courses/${this.course_id}?enrolled=true`)
        .then((res) => {
          if (res.status) {
            this.WeekSideBarLoading = false;
            this.courseDetails = res.data.data;
            this.$store.state.BreadCrumb.list?.length == 0
              ? this.$store.commit("BreadCrumb/AddBreadcrumb", {
                  text: res.data.data.name,
                  active: true,
                })
              : null;
            this.lesson_id = res.data.data.lessons[0]?.id;
            this.section_id = res.data.data.lessons[0]?.sections[0]?.id;
          }
        })
        .catch((err) => {
          this.WeekSideBarLoading = false;
        });
    },
    handleOpneSection(lesson_id, section_id) {
      this.lesson_id = lesson_id;
      this.section_id = section_id;
    },
  },
};
</script>

<style lang="scss" src="./_courseDetails.scss"></style>
