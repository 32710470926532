<template>
  <div class="resources" v-if="page_data && page_data.length > 0">
    <h2>{{ $t("g.resources") }}</h2>
    <div v-for="(resourse, index) of page_data" :key="resourse.id">
      <div v-if="resourse.type.includes('video')" class="playe p-1 mx-1">
        <div class="video-container">
          <video
            class="video"
            :controls="false"
            :id="index"
            ref="videoPlayer"
            played="true"
            @pause="handleVideoPause(index, $event)"
          >
            >
            <source :src="resourse.path" type="video/mp4" />
          </video>
          <img
            class="icon_video"
            :id="`svg-${index}`"
            @click="handelvedio(index)"
            :src="require('@/assets/images/icons/video_icon.png')"
          />
        </div>
      </div>
      <div v-else-if="resourse.type.includes('image')" class="mx-1 img">
        <img :src="resourse.path" :alt="resourse.description" />
      </div>
      <div v-else class="mx-1">
        <a :href="resourse.path" target="_blank" :id="resourse.id">
          <img :src="chooseImg(resourse.type)" style="width: 20px" />
          <span class="content-resource mx-1"> {{ resourse.name }} </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
export default {
  name: "SectionResources",
  components: {
    BRow,
    BCol,
  },
  props: ["page_data"],
  data() {
    return {
      resourseImg: null,
    };
  },

  methods: {
    handelvedio(id) {
      const video = document.getElementById(id);
      video.play();
      const cuurentVideoSVG = document.getElementById(`svg-${id}`);
      cuurentVideoSVG.style.display = "none";
      video.controls = true;
    },
    handleVideoPause(id, e) {
      if (e.target.paused) {
        const cuurentVideoSVG = document.getElementById(`svg-${id}`);
        cuurentVideoSVG.style.display = "block";
        document.getElementById(id).controls = false;
      }
    },
    chooseImg(type) {
      if (type.includes("pdf")) {
        return require("@/assets/images/icons/Group.png");
      } else if (type.includes(".presentation")) {
        return require("@/assets/images/icons/pptx.png");
      } else if (type.includes(".wordprocessingml")) {
        return require("@/assets/images/icons/docx.png");
      }
    },
  },
};
</script>

<style scoped lang="scss" src="./_sectionResources.scss"></style>
