var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"addquizForm"},[_c('b-form',[_c('b-modal',{ref:"bv-modal-AddQuiz",attrs:{"id":"bv-modal-AddQuiz","centered":"","hide-footer":"","title":_vm.isEdit
          ? _vm.$t('g.add_quiz_overlay/title_edit')
          : _vm.$t('g.add_quiz_overlay/title')}},[_c('span',{staticClass:"sub-title m-2"},[_vm._v(" "+_vm._s(_vm.$t("g.add_quiz_overlay/header")))]),_c('div',{staticClass:"add-quiz-overlay px-2"},[_c('b-form-group',{staticClass:"mt-2",attrs:{"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{staticClass:"f-input",attrs:{"type":"text","placeholder":_vm.$t('g.name'),"name":"name"},model:{value:(_vm.form_data.name),callback:function ($$v) {_vm.$set(_vm.form_data, "name", $$v)},expression:"form_data.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',{staticStyle:{"position":"relative"}},[_c('b-form-select',{staticClass:"custom-select",model:{value:(_vm.form_data.assignment.assignment_type_id),callback:function ($$v) {_vm.$set(_vm.form_data.assignment, "assignment_type_id", $$v)},expression:"form_data.assignment.assignment_type_id"}},[_c('b-form-select-option',{attrs:{"disabled":"","value":"0"}},[_vm._v(" "+_vm._s(_vm.$t("g.add_quiz_overlay/form/quiz_type"))+" ")]),_vm._l((_vm.quizTypes),function(type){return _c('b-form-select-option',{key:type.value,attrs:{"value":type.value}},[_vm._v(" "+_vm._s(type.text)+" ")])})],2),_c('svg',{staticClass:"select-arrow",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","viewBox":"0 0 20 20","fill":"none"}},[_c('path',{attrs:{"d":"M15.8346 7.5L10.0013 13.3333L4.16797 7.5","stroke":"#5E5E5E","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0])+" ")])]}}])})],1),_c('b-row',{},[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"start date"}},[_c('validation-provider',{attrs:{"name":"start date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true, dateFormat: 'Y-m-d H:i' },"placeholder":_vm.$t('g.start_date')},model:{value:(_vm.form_data.assignment.start_date),callback:function ($$v) {_vm.$set(_vm.form_data.assignment, "start_date", $$v)},expression:"form_data.assignment.start_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"end date"}},[_c('validation-provider',{attrs:{"name":"end date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true, dateFormat: 'Y-m-d H:i' },"placeholder":_vm.$t('g.end_date')},model:{value:(_vm.form_data.assignment.due_date),callback:function ($$v) {_vm.$set(_vm.form_data.assignment, "due_date", $$v)},expression:"form_data.assignment.due_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)],1),_c('b-row',{},[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"maxScores"}},[_c('validation-provider',{attrs:{"name":_vm.$t('g.add_quiz_overlay/form/max_scores'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-form",attrs:{"type":"number","placeholder":_vm.$t('g.add_quiz_overlay/form/max_scores')},model:{value:(_vm.form_data.assignment.max_score),callback:function ($$v) {_vm.$set(_vm.form_data.assignment, "max_score", $$v)},expression:"form_data.assignment.max_score"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$helpers.translateValidations( errors[0], _vm.$t("g.add_quiz_overlay/form/max_scores") ) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"maxAttempts"}},[_c('validation-provider',{attrs:{"name":_vm.$t('g.add_quiz_overlay/form/max_attempts'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-form",attrs:{"type":"number","placeholder":_vm.$t('g.add_quiz_overlay/form/max_attempts')},model:{value:(_vm.form_data.assignment.max_attempts),callback:function ($$v) {_vm.$set(_vm.form_data.assignment, "max_attempts", $$v)},expression:"form_data.assignment.max_attempts"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$helpers.translateValidations( errors[0], _vm.$t("g.add_quiz_overlay/form/max_attempts") ) : errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"text-area",staticStyle:{"overflow":"hidden"},attrs:{"rows":"5","max-rows":"10","no-resize":"","placeholder":_vm.$t('g.add_quiz_overlay/form/introduction'),"name":"description"},model:{value:(_vm.form_data.introduction),callback:function ($$v) {_vm.$set(_vm.form_data, "introduction", $$v)},expression:"form_data.introduction"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1),_c('b-form-file',{ref:"fileInput",attrs:{"hidden":true,"accept":".jpg, .png, .gif","multiple":"","plain":""},model:{value:(_vm.selectedFiles),callback:function ($$v) {_vm.selectedFiles=$$v},expression:"selectedFiles"}}),(
            Array.isArray(_vm.form_data.resources) &&
            _vm.form_data.resources.length == 0
          )?_c('b-row',{staticClass:"px-1"},[_c('div',{staticClass:"import-continier w-100",on:{"click":function($event){return _vm.$refs.fileInput.$el.click()}}},[_c('span',{staticClass:"box"},[_c('img',{attrs:{"src":_vm.importImge,"alt":"image-import"}}),_c('img',{staticClass:"mt-3 ml-n1",attrs:{"src":_vm.importIco,"alt":"image-import"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.add_quiz_overlay/form/import_file_text")))])]),_c('br')])]):_c('b-row',{staticClass:"px-1"},[_c('span',[_vm._v(_vm._s(_vm.$t("g.Instructions")))]),_vm._l((_vm.form_data.resources),function(item){return _c('div',{key:item.name,staticClass:"media-preview w-100"},[_c('img',{staticClass:"sm-img-preview",attrs:{"src":_vm.pdfIco,"alt":"Instructions-Type"}}),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(item.name))]),_c('span',{staticClass:"float-right",on:{"click":function($event){return _vm.deleteItem(item.name)}}},[_c('img',{attrs:{"src":_vm.PenIco,"alt":"pen-tool"}})])])})],2)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end mt-2"},[_c('b-button',{staticClass:"mx-1",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$bvModal.hide('bv-modal-AddQuiz')}}},[_vm._v(_vm._s(_vm.$t("g.cancel")))]),_c('b-button',{ref:"formButton",staticClass:"form-btn",on:{"click":function($event){return _vm.validateForm()}}},[_vm._v(_vm._s(_vm.isEdit ? _vm.$t("g.edit") : _vm.$t("g.save")))])],1)],1),_c('overlay-component',{attrs:{"isLoading":_vm.isloading}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }