l
<template>
  <div class="weeks-sidebar" v-if="page_data">
    <div class="week-header">
      <h3 class="course-title">{{ page_data ? page_data.name : null }}</h3>
    </div>
    <div class="week-content">
      <b-card
        :class="
          activeCollapse === lesson.id
            ? 'week-box-component mb-1 active'
            : 'week-box-component mb-1'
        "
        v-for="lesson in page_data.lessons"
        :key="lesson.id"
      >
        <b-card-header
          @click="toggleCollapse(lesson.id, lesson.is_locked)"
          :class="
            activeCollapse === lesson.id
              ? 'active'
              : lesson.is_locked
              ? 'visiable'
              : null
          "
        >
          <div
            :class="
              activeCollapse === lesson.id
                ? 'card-header-text active'
                : 'card-header-text'
            "
          >
            <span v-if="lesson.name.split('').length < 15">
              {{ lesson.name }}
            </span>
            <span v-else>
              <span :id="'lesson-name-' + `${lesson.id}`">
                {{ lesson.name.substring(0, 15) }} ..
              </span>
              <b-tooltip
                :target="'lesson-name-' + `${lesson.id}`"
                variant="light"
                triggers="hover"
              >
                <b>
                  {{ lesson.name }}
                </b>
              </b-tooltip>
            </span>
          </div>
          <div>
            <b-dropdown
              :dropleft="lang === 'ar'"
              :dropright="lang === 'en'"
              no-caret
              variant="transparent"
              v-if="
                permissions.lessons &&
                (permissions.lessons.update || permissions.lessons.destroy)
              "
            >
              <template #button-content>
                <img
                  :src="activeCollapse === lesson.id ? white_dots : dots"
                  alt="three-dots"
                />
              </template>
              <b-dropdown-item
                v-if="permissions.lessons && permissions.lessons.update"
                @click="editLesson(lesson.id)"
              >
                {{ $t("g.edit") }}</b-dropdown-item
              >
              <b-dropdown-item
                v-if="permissions.lessons && permissions.lessons.destroy"
                @click="deleteLesson(lesson.id)"
              >
                {{ $t("g.delete") }}</b-dropdown-item
              >
              <b-dropdown-item
                v-if="permissions.lessons && permissions.lessons.update"
                @click="toggleVisulabiltyofLesson(lesson.id, lesson.is_locked)"
              >
                {{ !lesson.is_locked ? $t("g.lock") : $t("g.open") }}
              </b-dropdown-item>
            </b-dropdown>
            <b-img
              :src="activeCollapse === lesson.id ? arrowImg_w : arrowImg"
              alt="arrow icon"
            />
          </div>
        </b-card-header>
        <b-collapse :visible="activeCollapse === lesson.id">
          <b-card-body>
            <b-card-text v-for="section in lesson.sections" :key="section.id">
              <div
                @click="openSection(lesson.id, section.id)"
                style="cursor: pointer"
                class="custom-div"
              >
                <div>
                  <img :src="getSectionIcon(section)" alt="arrow icon" />
                  <span style="width: 100% !important">
                    {{ section.name }}
                  </span>
                </div>
                <span
                  class="actions-menu"
                  style="align-self: flex-end !important"
                >
                  <b-dropdown
                    :dropleft="lang === 'ar'"
                    :dropright="lang === 'en'"
                    no-caret
                    variant="transparent"
                    v-if="
                      section.is_assignment
                        ? permissions.assignments.destroy
                        : permissions.sections.destroy
                    "
                  >
                    <template #button-content>
                      <img
                        style="align-self: end"
                        :src="dots"
                        alt="three-dots"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="
                        section.is_assignment
                          ? permissions.assignments.update
                          : permissions.sections.update
                      "
                      @click="editSection(lesson.id, section)"
                    >
                      {{ $t("g.edit") }}</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="permissions.sections.destroy"
                      @click="
                        deleteSection(
                          lesson.id,
                          section.id,
                          section.is_assignment
                        )
                      "
                    >
                      {{ $t("g.delete") }}</b-dropdown-item
                    >
                  </b-dropdown>
                </span>
              </div>
            </b-card-text>
            <div
              class="add-section"
              v-if="
                permissions.sections &&
                (permissions.sections.store || permissions.assignments.store)
              "
            >
              <b-dropdown
                :dropright="lang === 'en'"
                :dropleft="lang === 'ar'"
                variant="tarnsparent "
                id="dropdown-dropright"
              >
                <template #button-content>
                  <div class="d-inline ml-n1 p-0 w-100">
                    <img :src="plus" alt="plus-icon" />
                    <span>{{ $t("g.add") }}</span>
                  </div>
                </template>
                <b-dropdown-item @click="AddNewLesson('lesson')">
                  {{ $t("g.lesson") }}</b-dropdown-item
                >
                <b-dropdown-item @click="AddNewLesson('liveLesson')">
                  {{ $t("g.live_lesson") }}
                </b-dropdown-item>
                <b-dropdown-item @click="AddNewLesson('quiz')">
                  {{ $t("g.asseigment") }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <AddWeekOverlay
      :id_to_edit="weekIdSentToOverlay"
      :course_id="page_data.id"
      @resetData="$emit('resetData')"
    />
    <AddLessonOverlay
      :id_to_edit="sectionIdToSentNewLesson"
      :course_id="page_data.id"
      :section_id="activeCollapse"
      @getSetcionId="updateLoaclToSection"
      @resetData="$emit('resetData')"
    />
    <AddLiveLessonOverlay
      :id_to_edit="sectionIdToSentNewLiveLesson"
      :course_id="page_data.id"
      :section_id="activeCollapse"
      @getSectionId="updateLoaclToSection"
      @resetData="$emit('resetData')"
    />
    <AddQuizOverlay
      :id_to_edit="sectionIdToSentNewQuiz"
      :course_id="page_data.id"
      :section_id="activeCollapse"
      @getSetcionId="updateLoaclToSection"
      @resetData="$emit('resetData')"
    />
    <OverlayComponent :isLoading="loading" />
  </div>
</template>

<script>
import {
  BProgress,
  BCardText,
  BCardHeader,
  BCollapse,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BCard,
  BImg,
  BTooltip,
} from "bootstrap-vue";
import AddWeekOverlay from "./overlaies/AddWeekOverlay/AddWeekOverlay.vue";
import AddLessonOverlay from "./overlaies/AddLessonOverlay/AddLessonOverLayComponet.vue";
import AddQuizOverlay from "./overlaies/AddQuizOverlay/AddQuizOverlay.vue";
import AddLiveLessonOverlay from "./overlaies/AddLiveLessonOverlay/AddLiveLessonOverlay.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
export default {
  name: "WeekSidebar",
  components: {
    BCardText,
    BProgress,
    BCardHeader,
    BCollapse,
    BCardBody,
    BCard,
    BImg,
    BDropdown,
    BDropdownItem,
    AddWeekOverlay,
    AddLessonOverlay,
    AddQuizOverlay,
    BTooltip,
    AddLiveLessonOverlay,
    OverlayComponent,
  },
  props: {
    page_data: Object,
    permissions: Object,
  },

  data() {
    return {
      infoImg: require("@/assets/images/icons/info.png"),
      arrowImg: require("@/assets/images/icons/cheveron-down.png"),
      infoImg_w: require("@/assets/images/icons/info-w.png"),
      arrowImg_w: require("@/assets/images/icons/arrow-w.png"),
      pen: require("@/assets/images/icons/pencil-alt.png"),
      locked: require("@/assets/images/icons/lock.png"),
      dots: require("@/assets/images/icons/information-circle.svg"),
      white_dots: require("@/assets/images/icons/white.svg"),
      plus: require("@/assets/images/icons/plus-p.svg"),
      group: require("@/assets/images/icons/Group.png"),
      liveIcon: require("@/assets/images/icons/liveIcon.svg"),
      bg_color: null,
      value: 52,
      activeCollapse: null,
      sectionidTosend: null,
      weekIdSentToOverlay: null,
      sectionIdToSentNewLesson: null,
      sectionIdToSentNewLiveLesson: null,
      sectionIdToSentNewQuiz: null,
      loading: false,
    };
  },
  computed: {
    lang() {
      return window.localStorage.getItem("lang");
    },
  },
  watch: {
    w_loading(newVal) {
      this.loading = newVal;
    },
  },
  methods: {
    addWeek() {
      this.weekIdSentToOverlay = null;
      this.$bvModal.show("bv-modal-AddWeek");
    },
    toggleCollapse(lessonsId, is_locked) {
      if (true) {
        if (this.activeCollapse === lessonsId) {
          this.activeCollapse = null;
        } else {
          this.activeCollapse = lessonsId;
        }
      }
    },
    openSection(lesson_id, section_id) {
      this.$emit("openSection", lesson_id, section_id);
    },
    AddNewLesson(type) {
      switch (type) {
        case "lesson":
          this.sectionIdToSentNewLesson = null;
          this.$bvModal.show("bv-modal-AddLesson");
          break;
        case "liveLesson":
          this.sectionIdToSentNewLiveLesson = null;
          this.$bvModal.show("bv-modal-AddLiveLesson");
          break;
        case "quiz":
          this.sectionIdToSentNewQuiz = null;
          this.$bvModal.show("bv-modal-AddQuiz");
          break;
        default:
          break;
      }
    },
    updateLoaclToSection() {
      this.sectionidTosend = this.activeCollapse;
    },
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    toggleVisulabiltyofLesson(id, status) {
      this.loading = true;
      const courseId = this.page_data.id;
      const lessonStatus = status;
      let formDate = new FormData();
      formDate.append("is_locked", lessonStatus ? "0" : "1");
      this.$http
        .post(`admin/courses/${courseId}/lessons/${id}?_method=put`, formDate)
        .then((response) => {
          this.loading = false;
          this.makeToast("sucess", response.message);
          this.$emit("resetData");
        })
        .catch((error) => {
          this.loading = false;

          this.makeToast("danger", error.message, this.$t("g.send.errorTitle"));
        });
    },
    editLesson(id) {
      this.weekIdSentToOverlay = id;
      this.$bvModal.show("bv-modal-AddWeek");
    },
    editSection(lesson, section) {
      if (section.is_assignment) {
        this.sectionIdToSentNewQuiz = section;
        this.$bvModal.show("bv-modal-AddQuiz");
      } else {
        if (section.meet_link) {
          this.sectionIdToSentNewLiveLesson = section.id;
          this.$bvModal.show("bv-modal-AddLiveLesson");
        } else {
          this.sectionIdToSentNewLesson = section.id;
          this.$bvModal.show("bv-modal-AddLesson");
        }
      }
    },
    deleteSection(lesson, section) {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!" ,
        cancelButtonText: this.$t("g.cancel"),
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const courseId = this.page_data.id;

          this.$http.delete(
            `admin/courses/${courseId}/lessons/${lesson}/sections/${section}`
          );
          this.$emit("resetData");
          this.$swal({
            icon: "success",
            title: this.$t("g.deleted"), //"Deleted!"
            text: this.$t("g.week_sideBar/delete_section_alart"), //"Your file has been deleted."
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },

    deleteLesson(id) {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!" ,
        cancelButtonText: this.$t("g.cancel"),
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const courseId = this.page_data.id;
          this.$http
            .delete(`admin/courses/${courseId}/lessons/${id}`)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: this.$t("g.deleted"), //"Deleted!"
                text: this.$t("g.week_sideBar/delete_week_alart"), //"Your file has been deleted."
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.$emit("resetData");
            })
            .catch((err) => {
              this.$bvToast.toast(body, {
                title: "error while delete lesson",
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    getSectionIcon(section) {
      return section.is_assignment
        ? this.pen
        : section.meet_link
        ? this.liveIcon
        : this.group;
    },
  },
};
</script>

<style lang="scss" src="./_weeksSidebar.scss"></style>
