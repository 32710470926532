var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"addlessonForm"},[_c('b-form',[_c('b-modal',{ref:"bv-modal-AddLesson",attrs:{"id":"bv-modal-AddLesson","centered":"","hide-footer":"","title":_vm.isEdit
          ? _vm.$t('g.add_lesson_overlay/titl_edit')
          : _vm.$t('g.add_lesson_overlay/title')}},[_c('span',{staticClass:"sub-title m-2"},[_vm._v(" "+_vm._s(_vm.$t("g.add_lesson_overlay/header")))]),_c('div',{staticClass:"add-lesson-overlay"},[_c('b-form-group',{staticClass:"m-2",attrs:{"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input",attrs:{"type":"text","placeholder":_vm.$t('g.name'),"name":"name"},model:{value:(_vm.form_data.name),callback:function ($$v) {_vm.$set(_vm.form_data, "name", $$v)},expression:"form_data.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mx-2",attrs:{"label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"input text-area",attrs:{"rows":"5","max-rows":"10","no-resize":"","placeholder":_vm.$t('g.description'),"name":"description"},model:{value:(_vm.form_data.introduction),callback:function ($$v) {_vm.$set(_vm.form_data, "introduction", $$v)},expression:"form_data.introduction"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mx-2",attrs:{"label-for":"start date"}},[_c('validation-provider',{attrs:{"name":"start date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control py-1",attrs:{"config":{ enableTime: true, dateFormat: 'Y-m-d H:i' },"placeholder":_vm.$t('g.start_date')},model:{value:(_vm.form_data.start_date),callback:function ($$v) {_vm.$set(_vm.form_data, "start_date", $$v)},expression:"form_data.start_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1),_c('b-form-file',{ref:"fileInput",attrs:{"hidden":true,"multiple":"","plain":""},model:{value:(_vm.selectedFiles),callback:function ($$v) {_vm.selectedFiles=$$v},expression:"selectedFiles"}}),(
            Array.isArray(_vm.form_data.resources) &&
            _vm.form_data.resources.length == 0
          )?_c('b-row',{staticClass:"mx-2"},[_c('div',{staticClass:"import-continier w-100",on:{"click":function($event){return _vm.$refs.fileInput.$el.click()}}},[_c('span',{staticClass:"box mt-2"},[_c('img',{attrs:{"src":_vm.importImge,"alt":"image-import"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.add_lesson_overlay/form/import_file_text")))])]),_c('br')])]):_c('b-row',{staticClass:"mx-2"},[_c('span',[_vm._v(_vm._s(_vm.$t("g.Instructions")))]),_vm._l((_vm.form_data.resources),function(item){return _c('div',{key:item.name,staticClass:"resources-preview w-100"},[_c('img',{staticClass:"sm-img-preview",attrs:{"src":_vm.pdfIco,"alt":"Instructions-Type"}}),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(item.name))]),_c('span',{staticClass:"float-right",on:{"click":function($event){return _vm.deleteItem(item.name)}}},[_c('img',{attrs:{"src":_vm.PenIco,"alt":"pen-tool"}})])])})],2)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end mt-2"},[_c('b-button',{staticClass:"mx-1 form-btn-outline",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$bvModal.hide('bv-modal-AddLesson')}}},[_vm._v(_vm._s(_vm.$t("g.cancel")))]),_c('b-button',{ref:"formButton",staticClass:"form-btn",on:{"click":function($event){return _vm.validateForm()}}},[_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t("g.edit") : _vm.$t("g.save")))])],1)],1),_c('overlay-component',{attrs:{"isLoading":_vm.isLoading}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }